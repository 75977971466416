import React from "react";
import {BrowserView, MobileView} from "react-device-detect";
import { ThemeContext } from "../components/theme";
// import "./index.css";

const Index = () => {
    const { theme } = React.useContext(ThemeContext);

    // const changeTheme = (event) => {
    //     setTheme(event.target.value);
    // };


    return (
        <body className={`bg-${theme}-50 h-full md:h-screen`} >
            <div  className={`relative flex flex-wrap border-double border-4 h-full border-${theme}-300`}>
                {/* 1,1*/}
                <div className={`font-serif text-4xl text-center text-${theme}-300 flex-shrink col-span-1 row-span-1 md:row-end-1 md:h-18 border-single md:border-r-2 border-b-2 w-full overflow-hidden sm:w-full md:w-1/3 lg:w-1/3 xl:w-1/3 border-${theme}-300 p-2 leading-tight`}> 
                    Javon Kitson
                </div>
                {/* 2,1*/}
                <div className={`font-serif md:text-3xl text-xl text-center text-${theme}-300 flex-shrink col-span-3 md:row-span-1 md:row-end-1 row-span-1 md:h-18 border-single border-b-2 w-full overflow-hidden sm:w-full md:w-2/3 lg:w-2/3 xl:w-2/3 border-${theme}-300 p-2 leading-tight`}> 
                    <BrowserView>
                        All Good Art is Science & All Good Science is Art
                    </BrowserView>
                    <MobileView>
                        All Good Art is Science <br/> & <br/> All Good Science is Art
                    </MobileView>
                </div>
                {/* 1,2*/}
                {/* Personal Introduction */}
                <div className={`font-serif flex-shrink-0 col-span-1 border-single md:border-r-2 border-b-2 w-full overflow-hidden sm:w-full md:w-1/3 lg:w-1/3 xl:w-1/3 border-${theme}-300 p-2`}> 
                    {/* <blockquote className={`text-left text-${theme}-300`}>
                        Hi, I&apos;m Javon. I&apos;m a software engineer based in the Baltimore Area. I&apos;m currently working at <a href="https://www.i-a-i.com/" rel="noreferrer" target="_blank" className={`text-${theme}-400`}>Intelligent Automation</a> as a Software Engineer.
                    </blockquote>
                    <br/> */}
                    <blockquote className={`text-left text-${theme}-300`}>
                        Hi, I&apos;m Javon. I&apos;m a software engineer based in the Washington DC Area.
                        I received my Bachelor&apos;s from Loyola University Maryland in 2020, where I studied Computer Science and minored in Biomedical Physics.
                        I graduated with a Master&apos;s degree in Applied Artificial Intelligence from the University of San Diego in 2024.
                        Currently, I work as a Research Engineer III at BlueHalo, where I am the primary administrator for our company&apos;s AI/ML cluster.
                        I have a passion for learning new things and I&apos;m always looking to improve my skills, particularly in the field of machine learning.
                    </blockquote>
                    <br/>
                    <blockquote className={`text-left text-${theme}-300`}>
                        Outside of work, I enjoy making websites and applications to gather, analyze, and act on stock market data.
                        I also enjoy growing vegetables and mushrooms, and I&apos;m a huge fan of the band Snarky Puppy.
                    </blockquote>
                </div>
                {/* 2,2*/}
                <div className={`2xl:h-full lg:h-144 md:h-160 overflow-hidden font-serif flex-shrink-0 m-auto border-single p-2 w-full sm:w-full md:w-2/3 lg:w-2/3 xl:w-2/3 border-${theme}-300`}> 
                {/* <Slider style={{height: '100%'}}> */}
                    <div className="flex flex-wrap m-auto md:-mx-1 overflow-scroll-y md:overflow-y-auto xl:-mx-2 gap-2 h-full w-full">
                    {/* h-72 max-w-md md:w-64 sm:w-72 w-full */}
                        <div onClick={()=> window.open("https://gitlab.com/p4428/javonkitson.com", "_blank")} className={`group cursor-pointer m-auto relative text-center font-serif text-3xl text-${theme}-50 bg-${theme}-400 hover:bg-${theme}-50 hover:shadow-2xl hover:border-${theme}-300 border-transparent border-2 border-single my-1 px-1 w-full overflow-hidden xl:my-2 xl:px-2 xl:w-1/3 h-72 max-w-md md:w-64 sm:w-72  rounded-lg `}>
                            <blockquote className={`text-left font-serif text-2xl text-${theme}-50 group-hover:text-${theme}-400`}>
                                Javonkitson.com
                            </blockquote>
                            <blockquote className={`text-left font-serif text-lg my-2 text-${theme}-200 group-hover:text-gray-900`}>
                                The website you&apos;re currently looking at.
                            </blockquote>
                            <blockquote className={`absolute text-left font-serif text-xl inset-x-0 bottom-0 p-2 text-${theme}-300 group-hover:text-gray-900`}>
                                Software Stack: 
                                <br/>Javascript, HTML, CSS, React, Tailwind CSS
                            </blockquote>
                        </div>
                        {/* <div onClick={()=> window.open("https://google.com", "_blank")} className={`group cursor-pointer m-auto relative text-center font-serif text-3xl text-${theme}-50 bg-${theme}-400 hover:bg-${theme}-50 hover:shadow-2xl hover:border-${theme}-300 border-transparent border-2 border-single my-1 px-1 w-full overflow-hidden xl:my-2 xl:px-2 xl:w-1/3 h-72 max-w-md md:w-64 sm:w-72  rounded-lg `}>
                            <blockquote className={`text-left font-serif text-2xl text-${theme}-50 group-hover:text-${theme}-400`}>
                                Distriubted System
                            </blockquote>
                            <blockquote className={`text-left font-serif text-lg my-2 text-${theme}-200 group-hover:text-gray-900`}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Commodo quis imperdiet massa tincidunt nunc pulvinar sapien et ligula.
                            </blockquote>
                            <blockquote className={`absolute text-left font-serif text-xl inset-x-0 bottom-0 p-2 text-${theme}-300 group-hover:text-gray-900`}>
                                Software Stack: C
                            </blockquote>
                        </div>
                        <div onClick={()=> window.open("https://google.com", "_blank")} className={`group cursor-pointer m-auto relative text-center font-serif text-3xl text-${theme}-50 bg-${theme}-400 hover:bg-${theme}-50 hover:shadow-2xl hover:border-${theme}-300 border-transparent border-2 border-single my-1 px-1 w-full overflow-hidden xl:my-2 xl:px-2 xl:w-1/3 h-72 max-w-md md:w-64 sm:w-72  rounded-lg `}>
                            <blockquote className={`text-left font-serif text-2xl text-${theme}-50 group-hover:text-${theme}-400`}>
                                CRUD Application
                            </blockquote>
                            <blockquote className={`text-left font-serif text-lg my-2 text-${theme}-200 group-hover:text-gray-900`}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            </blockquote>
                            <blockquote className={`absolute text-left font-serif text-xl inset-x-0 bottom-0 p-2 text-${theme}-300 group-hover:text-gray-900`}>
                                Software Stack:
                                <br/>Python, Javascript, HTML, SQL, React, Tailwind CSS, Docker
                            </blockquote>
                        </div> */}
                        <div onClick={()=> window.open("https://apps.apple.com/us/app/vision-words/id6473125420", "_blank")} className={`group cursor-pointer m-auto relative text-center font-serif text-3xl text-${theme}-50 bg-${theme}-400 hover:bg-${theme}-50 hover:shadow-2xl hover:border-${theme}-300 border-transparent border-2 border-single my-1 px-1 w-full overflow-hidden xl:my-2 xl:px-2 xl:w-1/3 h-72 max-w-md md:w-64 sm:w-72 rounded-lg `}>
                            <blockquote className={`text-left font-serif text-2xl text-${theme}-50 group-hover:text-${theme}-400`}>
                                Vision Words
                            </blockquote>
                            <blockquote className={`text-left font-serif text-lg my-2 text-${theme}-200 group-hover:text-gray-900`}>
                                IOS Text to Image app
                            </blockquote>
                            <blockquote className={`absolute text-left font-serif text-xl inset-x-0 bottom-0 p-2 text-${theme}-300 group-hover:text-gray-900`}>
                                Software Stack:
                                <br/> Python, Flutter, AWS
                            </blockquote>
                        </div>
                        <div onClick={() => window.open("/AAI590Capstone.pdf", "_blank")} className={`group cursor-pointer m-auto relative text-center font-serif text-3xl text-${theme}-50 bg-${theme}-400 hover:bg-${theme}-50 hover:shadow-2xl hover:border-${theme}-300 border-transparent border-2 border-single my-1 px-1 w-full overflow-hidden xl:my-2 xl:px-2 xl:w-1/3 h-72 max-w-md md:w-64 sm:w-72 rounded-lg `}>
                            <blockquote className={`text-left font-serif text-2xl text-${theme}-50 group-hover:text-${theme}-400`}>
                                Reinforcement Learning Stock Trading
                            </blockquote>
                            <blockquote className={`text-left font-serif text-lg my-2 text-${theme}-200 group-hover:text-gray-900`}>
                                Soft Actor Critic Algorithm
                            </blockquote>
                            <blockquote className={`absolute text-left font-serif text-xl inset-x-0 bottom-0 p-2 text-${theme}-300 group-hover:text-gray-900`}>
                                Software Stack:
                                <br/> python, tensorflow, keras, pandas, numpy
                            </blockquote>
                        </div>
                        <div onClick={()=> window.open("https://google.com", "_blank")} className={`group cursor-pointer m-auto relative text-center font-serif text-3xl text-${theme}-50 bg-${theme}-400 hover:bg-${theme}-50 hover:shadow-2xl hover:border-${theme}-300 border-transparent border-2 border-single my-1 px-1 w-full overflow-hidden xl:my-2 xl:px-2 xl:w-1/3 h-72 max-w-md md:w-64 sm:w-72 rounded-lg `}>
                            <blockquote className={`text-left font-serif text-2xl text-${theme}-50 group-hover:text-${theme}-400`}>
                                Reagan Chat
                            </blockquote>
                            <blockquote className={`text-left font-serif text-lg my-2 text-${theme}-200 group-hover:text-gray-900`}>
                                A chatbot made to respond in the style of Ronald Reagan
                            </blockquote>
                            <blockquote className={`absolute text-left font-serif text-xl inset-x-0 bottom-0 p-2 text-${theme}-300 group-hover:text-gray-900`}>
                                Software Stack:
                                <br/> python, flask, react, docker
                            </blockquote>
                        </div>
                        <div onClick={()=> window.open("https://google.com", "_blank")} className={`group cursor-pointer m-auto relative text-center font-serif text-3xl text-${theme}-50 bg-${theme}-400 hover:bg-${theme}-50 hover:shadow-2xl hover:border-${theme}-300 border-transparent border-2 border-single my-1 px-1 w-full overflow-hidden xl:my-2 xl:px-2 xl:w-1/3 h-72 max-w-md md:w-64 sm:w-72 rounded-lg `}>
                            <blockquote className={`text-left font-serif text-2xl text-${theme}-50 group-hover:text-${theme}-400`}>
                                Coming Soon...
                            </blockquote>
                            <blockquote className={`text-left font-serif text-lg my-2 text-${theme}-200 group-hover:text-gray-900`}>
                                ????????
                            </blockquote>
                            <blockquote className={`absolute text-left font-serif text-xl inset-x-0 bottom-0 p-2 text-${theme}-300 group-hover:text-gray-900`}>
                                Software Stack:
                                <br/> ????????
                            </blockquote>
                        </div>
                        <div onClick={()=> window.open("https://google.com", "_blank")} className={`group cursor-pointer m-auto relative text-center font-serif text-3xl text-${theme}-50 bg-${theme}-400 hover:bg-${theme}-50 hover:shadow-2xl hover:border-${theme}-300 border-transparent border-2 border-single my-1 px-1 w-full overflow-hidden xl:my-2 xl:px-2 xl:w-1/3 h-72 max-w-md md:w-64 sm:w-72 rounded-lg `}>
                            <blockquote className={`text-left font-serif text-2xl text-${theme}-50 group-hover:text-${theme}-400`}>
                                Coming Soon...
                            </blockquote>
                            <blockquote className={`text-left font-serif text-lg my-2 text-${theme}-200 group-hover:text-gray-900`}>
                                ????????
                            </blockquote>
                            <blockquote className={`absolute text-left font-serif text-xl inset-x-0 bottom-0 p-2 text-${theme}-300 group-hover:text-gray-900`}>
                                Software Stack:
                                <br/> ????????
                            </blockquote>
                        </div>
                    </div>
                </div>
                {/* 3,1*/}
                <div className={`text-2xl text-${theme}-200 text-center font-serif flex-shrink-0 col-span-1 lg:border-r-2  md:border-r-2 overflow-hidden sm:w-full md:w-1/3 lg:w-1/3 xl:w-1/3  w-full border-${theme}-300 p-2`}> 
                    {/* <button onClick={slideLeft} className={`inline float-left bg-${theme}-400 active:bg-${theme}-50 active:text-${theme}-400 text-${theme}-50 active:border-${theme}-300 border-transparent border-2 border-single rounded-full h-10 w-10`}> {"< "} </button> */}
                    <div className={` text-center text-${theme}-400`}>
                        <a className="mailto" href="mailto:javonkitson@gmail.com?subject=Resume&body=Hi Javon, Would you mind sending me a copy of your resume?"  target="_blank" rel="noopener noreferrer">Resume</a>
                    </div>
                    {/* <button onClick={slideRight} className={`inline float-right bg-${theme}-400 active:bg-${theme}-50 active:text-${theme}-400 text-${theme}-50 active:border-${theme}-300 border-transparent border-2 border-single rounded-full h-10 w-10`}> {" >"} </button> */}
                </div>

            </div>
        </body>
    );
};

export default Index;